import React from "react"
import Layout from "../components/layout"
import Moment from "react-moment"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import BackgroundImage from "gatsby-background-image"
import Title from "../components/title"
import SEO from "../components/seo"
import { TunesPageProps } from "../@types/pages/tunes"
import tw from "twin.macro"

export const query = graphql`
  query {
    allLastfmPlayback(sort: { order: DESC, fields: date }) {
      edges {
        node {
          fields {
            date
          }
          track {
            name
            url
            coverImg {
              childImageSharp {
                fixed(width: 300) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
            artist {
              name
            }
          }
        }
      }
    }
  }
`

export const PureTunesPage: React.FC<TunesPageProps> = ({ data }) => {
  const Wall = styled.section`
    ${tw`flex flex-row flex-wrap justify-center mt-6`};
  `
  const Cover = styled(BackgroundImage)`
    &:hover,
    &:active {
      div {
        visibility: visible;
      }
    }
  `

  const Time = styled(Moment)`
    ${tw`text-2xl w-11/12`};
  `
  const Played = styled.span`
    ${tw`text-2xl w-11/12 mt-2`};
  `

  const Name = styled.span`
    ${tw`text-3xl w-11/12`}
  `

  const CoverText = styled.div`
    ${tw`
      h-full w-full flex flex-col justify-center text-center items-center
      xl:invisible
    `};
    color: #fff;
    transition: ease-in 0.1s;
    background-color: rgba(0, 0, 0, 0.5);
    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  `

  const Hr = styled.hr`
    ${tw`w-11/12`};
    color: #fff;
  `

  const TitleContainer = styled.div`
    ${tw`self-center md:w-full md:w-10/12 lg:w-11/12`};
  `

  return (
    <>
      <TitleContainer>
        <Title title={"Tunes"} />
      </TitleContainer>
      <Wall>
        {data.allLastfmPlayback.edges.map(({ node }, i) =>
          node.track.coverImg !== null ? (
            node.track.coverImg.childImageSharp !== null ? (
              <a href={node.track.url} key={i}>
                <Cover
                  Tag={"section"}
                  fixed={node.track.coverImg.childImageSharp.fixed}
                >
                  <CoverText>
                    <Name>
                      {node.track.artist.name} - {node.track.name}
                    </Name>
                    <Hr />
                    <Played>Played</Played>
                    <Time
                      date={node.fields.date}
                      fromNow={true}
                      interval={60}
                    />
                  </CoverText>
                </Cover>
              </a>
            ) : (
              ""
            )
          ) : (
            ""
          )
        )}
      </Wall>
    </>
  )
}
/**
 * Tunes page
 *
 * @param data
 * @constructor
 */
const TunesPage: React.FC<TunesPageProps> = ({ data }) => {
  return (
    <Layout>
      <SEO title={"Tunes"} />
      <PureTunesPage data={data} />
    </Layout>
  )
}

export default TunesPage
